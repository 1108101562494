import { SearchBar as DSSearchBar } from '@cash-me/react-components'
import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, media, radii, shadows, space, zIndices } = theme

export const QuickHelpWrapper = styled.div`
  position: relative;
  display: none;

  @media (${media.desktop}) {
    display: block;
  }
`

export const QuickHelp = styled.div`
  position: absolute;
  display: block;
  z-index: ${zIndices[2]};
  right: ${space['spacing-md']};
  top: ${space['spacing-md']};
  width: 18rem;
  max-width: 18rem;
  border-radius: ${radii['corner-radius-sm']};
  background-color: ${colors['neutral-background-default']};
  box-shadow: ${shadows['shadow-level-2']} rgba(0 0 0 / 12%);

  .ds-accordion-item {
    margin: 0;

    summary {
      align-items: center;
      padding: ${space['spacing-sm']};
    }
  }
`

export const ContactUs = styled.div`
  background-color: ${colors['neutral-background-contrast-medium']};
  padding: ${space['spacing-lg']} ${space['spacing-md']};
  border-radius: 0 0 ${radii['corner-radius-sm']} ${radii['corner-radius-sm']};

  > p {
    margin-bottom: ${space['spacing-sm']};
  }
`

export const SearchBar = styled(DSSearchBar)`
  margin-bottom: ${space['spacing-lg']};
`

export const List = styled.div`
  width: 100%;
  padding: ${space['spacing-sm']} ${space['spacing-md']} ${space['spacing-lg']};

  a {
    margin-right: auto;
  }

  > a:last-child {
    margin-top: ${space['spacing-md']};
  }
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${space['spacing-xxs']} 0;

  > p {
    flex: 1;
  }
`
