import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, fontSizes, media, space, zIndices } = theme

export const CloseButton = styled.button`
  all: unset;
  display: none;
  position: absolute;
  right: -2.25rem;
  top: ${space['spacing-sm']};
  cursor: pointer;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${space['spacing-sm']};
`

export const Wrapper = styled.div`
  z-index: ${zIndices[3]};
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease-in-out;

  &[data-show='true'] {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${colors['neutral-background-contrast']}E6;
    padding-right: ${space['spacing-xl']};
    transform: translateX(0%);

    ${CloseButton} {
      display: block;
    }
  }

  @media (${media.desktop}) {
    display: none;
  }
`

export const Content = styled.div`
  position: relative;
  height: 100%;
  width: min(100%, 24rem);
  background-color: ${colors['neutral-background-default']};
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: ${space['spacing-sm']};
  gap: ${space['spacing-md']};

  > * {
    flex: 1 0 auto;
  }

  .accordion-support {
    h4 {
      color: ${colors['neutral-text-default']};
    }
  }

  .accordion-item {
    button {
      padding: ${space['spacing-xs']} 0;
    }

    .business-icon {
      vertical-align: middle;
    }
  }
`

export const AccordionLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space['spacing-xs']};
  margin-left: ${space['spacing-xs']};
  gap: ${space['spacing-md']};

  a {
    font-size: ${fontSizes['font-size-body-2']};
    color: ${colors['neutral-text-weak']};
    margin-right: auto;
  }
`

export const SidebarItem = styled.div`
  > :first-child {
    margin-bottom: ${space['spacing-sm']};
  }

  a,
  a:visited {
    all: unset;
    display: flex;
    align-items: center;
    margin-top: ${space['spacing-xxxs']};
    gap: ${space['spacing-xs']};
    cursor: pointer;

    > span {
      margin-bottom: ${space['spacing-xxxs']};
    }
  }
`

export const Close = styled.div`
  width: ${space['spacing-xxxl']};
  background-color: transparent;
`
