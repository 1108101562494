import { useCallback } from 'react'

export const useScrollLock = () => {
  const lockScroll = useCallback(() => {
    if (typeof document === 'undefined') return

    const { body } = document
    if (!body || !body.style) return

    body.style.overflow = 'hidden'
  }, [])

  const unlockScroll = useCallback(() => {
    if (typeof document === 'undefined') return

    const { body } = document
    if (!body || !body.style) return

    body.style.overflow = ''
  }, [])

  return [lockScroll, unlockScroll]
}
