import { FC, memo, useCallback, useState } from 'react'

import { Text, Icon } from '@cash-me/react-components'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Grid, Col } from 'components/grid'
import useLocalStorage from 'hooks/useLocalStorage'

import * as S from './styles'

const Warning: FC = memo(() => {
  const router = useRouter()
  const { setItem } = useLocalStorage()

  const [showWarning, setShowWarning] = useState(true)

  const handleCloseWarning = useCallback(() => {
    setItem('cashme-warning', new Date())
    setShowWarning(false)
  }, [setItem])

  if (!showWarning) return <></>

  return (
    <S.WarningWrap>
      <Grid col={{ phone: 1, tablet: 1 }}>
        <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
          <S.Warning>
            <S.Content>
              <Icon name="attentionCircle" size={24} color="var(--colors-blue-icon-strong)" />
              <Text size="body1" color="var(--colors-neutral-text-contrast-default)">
                A CashMe {' '}
                <Text as="span" size="body1" weight="bold" color="var(--colors-neutral-text-contrast-default)">
                  não solicita 
                </Text> {' '}
                depósito ou pagamento antecipado para aprovação de crédito.
              </Text>
            </S.Content>
            <div>
              {router.pathname !== '/seguranca' && 
                <Link href="/seguranca">
                  Evite Golpes
                </Link>
              }
              <S.Close aria-label="Fechar aviso" onClick={handleCloseWarning}>
                <Icon name="close" size={24} color="var(--colors-blue-icon-strong)" />
              </S.Close>
            </div>
          </S.Warning>
        </Col>
      </Grid>
    </S.WarningWrap>
  )
})

Warning.displayName = 'Warning'

export default Warning
