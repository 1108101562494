import styled from 'styled-components'

import { theme } from 'styles/theme'

const { media, space } = theme

export const Navbar = styled.header`
  display: flex;
  align-items: center;
  height: 3.938rem;
  width: 100%;
`

export const Logo = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  @media (${media.desktop}) {
    padding: 0 ${space['spacing-md']};
  }
`

export const MenuButton = styled.button`
  all: unset;
  display: block;
  margin-top: ${space['spacing-xxxs']};
  margin-left: auto;
  margin-right: ${space['spacing-xs']};
  cursor: pointer;

  @media (${media.tablet}) {
    margin-right: ${space['spacing-md']};
  }

  @media (${media.desktop}) {
    display: none;
  }
`
