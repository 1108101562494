import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, media, space } = theme

export const Wrapper = styled.section`
  display: none;

  @media (${media.desktop}) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    background-color: ${colors['neutral-background-default']};
    padding: 0 ${space['spacing-md']};
    gap: ${space['spacing-md']};
  }
`

export const Trigger = styled.button`
  display: flex;
  cursor: pointer;
  transition: transform 0.2s ease;

  p {
    align-self: self-end;
  }

  &[data-selected] {
    svg {
      transform: rotate(180deg);
    }
  }
`

export const Dropdown = styled.ul`
  display: flex;
  flex-direction: column;
  width: 25.625rem;
  background-color: ${colors['neutral-background-default']};
  margin-top: ${space['spacing-xxs']};
  padding: ${space['spacing-md']};
  gap: ${space['spacing-md']};
  box-shadow: 0 ${space['spacing-xxxs']} ${space['spacing-xxxs']} rgba(0 0 0 / 25%);
  border-radius: 0 0 ${space['spacing-xxs']} ${space['spacing-xxs']};

  li > a,
  li > a:visited {
    color: ${colors['neutral-text-weak']};
    margin-right: auto;
  }
`

export const MenuItem = styled.div`
  > p.title {
    margin-bottom: ${space['spacing-xxs']};
  }

  > p:not(.title) {
    margin-bottom: ${space['spacing-sm']};
  }
`
