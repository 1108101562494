import { useState, useEffect, useRef, useCallback } from 'react'
interface WindowUtils {
  width?: number
  height?: number
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isHD: boolean
}

type Ref = ReturnType<typeof setInterval> | null

export default function useWindowSize() {
  const timeout = useRef<Ref>(null)
  const [windowSize, setWindowSize] = useState<WindowUtils>({
    width: undefined,
    height: undefined,
    isMobile: true,
    isTablet: false,
    isDesktop: false,
    isHD: false
  })

  const handleWindowSize = useCallback(() => {
    const width = window.innerWidth
    const height = window.innerHeight

    setWindowSize({
      width,
      height,
      isMobile: width <= 767,
      isTablet: width >= 768,
      isDesktop: width >= 1240,
      isHD: width >= 1440
    })
  }, [])

  useEffect(() => {
    handleWindowSize()
  }, [handleWindowSize])

  const handleResize = useCallback(() => {
    timeout?.current && clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      handleWindowSize()
    }, 100)
  }, [handleWindowSize])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return windowSize
}
