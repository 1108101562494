import styled from 'styled-components'

import { theme } from 'styles/theme'

const { media, colors, zIndices } = theme

export const Container = styled.div<{ hascta: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${({ hascta }) => hascta && '7.5rem'};

  @media (${media.desktop}) {
    padding-bottom: 0;
  }
`

export const TopHeader = styled.div`
  position: relative;
  z-index: ${zIndices[4]};
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  background-color: ${colors['neutral-background-default']};

  @media (${media.tablet}) {
    flex-direction: column;
  }
`
