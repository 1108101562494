import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, space, media, radii, fontWeights } = theme

export const WarningWrap = styled.div`
  width: 100%;
  background-color: ${colors['neutral-background-contrast']};
`

export const Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: ${space['spacing-sm']};
  padding: ${space['spacing-sm']};

  a {
    display: inline-block;
    color: ${colors['neutral-text-contrast-default']};
    border: 1px solid ${colors['neutral-text-contrast-default']};
    padding: ${space['spacing-xs']} ${space['spacing-sm']};
    border-radius: ${radii['corner-radius-sm']};
    font-weight: ${fontWeights['font-weight-medium']};
  }

  a:hover {
    background-color: ${colors['neutral-background-contrast-medium']};
  }

  a + button {
    top: ${space['spacing-xxs']};
  }


  @media (${media.desktop}) {
    flex-direction: row;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: ${space['spacing-sm']};

  @media (${media.phone}) {
    .ds-icon {
      display: none;
    }
  }
`

export const Close = styled.button`
  display: inline-block;
  width: ${space['spacing-xl']};
  cursor: pointer;
  position: absolute;
  top: ${space['spacing-sm']};
  right: ${space['spacing-sm']};

  @media (${media.desktop}){
    position: relative;
    top: 0;
    right: unset;
    left: ${space['spacing-sm']};
  }
`
