import { RefObject, useCallback, useEffect } from 'react'

type Handler = (event: MouseEvent) => void

export default function useClickOutside(ref: RefObject<HTMLElement>, handler: Handler): void {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const el = ref?.current
      if (!el || el.contains(e.target as Node)) return

      handler(e)
    },
    [ref, handler]
  )

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside, false)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside, false)
    }
  }, [handleClickOutside])
}
