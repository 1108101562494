import styled from 'styled-components'

import { theme } from 'styles/theme'

interface GridProps {
  readonly col: { phone: string | number; tablet: string | number; desktop: string | number }
  readonly gap: { phone: string | number; tablet: string | number; desktop: string | number }
}

const { media, space } = theme

const getGapSize = {
  none: 0,
  xxs: space['spacing-xxs'],
  xs: space['spacing-xs'],
  sm: space['spacing-sm'],
  md: space['spacing-md'],
  lg: space['spacing-lg'],
  xl: space['spacing-xl'],
  xxl: space['spacing-xxl'],
  xxxl: space['spacing-xxxl']
}

export const Grid = styled.div<GridProps>`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: ${({ col }) => `repeat(${col.phone}, minmax(0, 1fr))`};
  gap: ${({ gap }) => getGapSize[gap.phone]};
  width: 100%;
  margin: 0 auto;
  padding-left: ${space['spacing-md']};
  padding-right: ${space['spacing-md']};

  @media (${media.tablet}) {
    grid-template-columns: ${({ col }) => `repeat(${col.tablet}, minmax(0, 1fr))`};
    gap: ${({ gap }) => getGapSize[gap.tablet]};
  }

  @media (${media.desktop}) {
    grid-template-columns: ${({ col }) => `repeat(${col.desktop}, minmax(0, 1fr))`};
    max-width: 90rem;
    gap: ${({ gap }) => getGapSize[gap.desktop]};
  }
`
