import styled from 'styled-components'

import { theme } from 'styles/theme'

const { zIndices } = theme

export const Popper = styled.div`
  transition: visibility 0.1s, opacity ease 0.3s;
  z-index: ${zIndices[3]};
  visibility: hidden;
  opacity: 0;

  &[data-show] {
    visibility: visible;
    opacity: 1;
  }
`
