import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { usePopper } from 'react-popper'

import useClickOutside from 'src/presentation/hooks/useClickOutside'

import { PopperProps } from './popper.model'
import * as S from './styles'

export const Popper: FC<PopperProps> = ({ children, placement = 'bottom', className = '' }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const popperElementRef = useRef<HTMLDivElement>(null)
  const wrapperElementRef = useRef<HTMLDivElement>(null)

  const popperInstance = usePopper(referenceElement, popperElementRef.current, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10]
        }
      }
    ]
  })

  const togglePopper = useCallback(() => {
    const popperElement = popperElementRef?.current
    if (popperElement && referenceElement) {
      popperElement.toggleAttribute('data-show')
      referenceElement.toggleAttribute('data-selected')
    }
  }, [popperElementRef, referenceElement])

  useEffect(() => {
    if (referenceElement) referenceElement.addEventListener('click', togglePopper)

    return () => {
      if (referenceElement) referenceElement.removeEventListener('click', togglePopper)
    }
  }, [referenceElement, togglePopper, popperInstance])

  useClickOutside(wrapperElementRef, () => {
    const popperElement = popperElementRef?.current

    if (popperElement && referenceElement) {
      popperElement.removeAttribute('data-show')
      referenceElement.removeAttribute('data-selected')
    }
  })

  return (
    <div ref={wrapperElementRef}>
      {React.Children.map(children, (child, i) => {
        if (!React.isValidElement(child)) return null

        if (i === 0) {
          return React.cloneElement(child, { ...child.props, ref: setReferenceElement })
        }

        return (
          <S.Popper
            ref={popperElementRef}
            className={className}
            style={{ ...popperInstance.styles['popper'] }}
            {...popperInstance.attributes['popper']}
          >
            {child}
          </S.Popper>
        )
      })}
    </div>
  )
}
