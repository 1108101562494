import { useCallback, useEffect, useState } from 'react'

import { Icon } from '@cash-me/react-components'
import Link from 'next/link'

import { Grid, Col } from 'components/grid'
import { Logo } from 'components/logo'
import { useScrollLock } from 'hooks/useScrollLock'
import useWindowSize from 'hooks/useWindowSize'
import { pushDataLayer } from 'utils/push-data-layer'

import { Sidebar } from './sidebar'
import * as S from './styles'
import { Topbar } from './topbar'

export const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const [lockScroll, unlockScroll] = useScrollLock()
  const { isMobile } = useWindowSize()

  const showSideBar = useCallback(() => {
    pushDataLayer({
      category: 'Clique',
      action: 'Header',
      label: 'Menu Icon'
    })

    setShowSidebar(true)
    lockScroll()
  }, [lockScroll])

  const closeSideBar = useCallback(() => {
    setShowSidebar(false)
    unlockScroll()
  }, [unlockScroll])

  useEffect(() => {
    if (!isMobile) setShowSidebar(false)
  }, [isMobile])

  return (
    <S.Navbar>
      <Grid col={{ phone: 2, tablet: 2, desktop: 12 }}>
        <Col col={{ tablet: 1, desktop: 2 }}>
          <S.Logo>
            <Link
              href="/"
              title="Logo Cashme"
              onClick={() =>
                pushDataLayer({
                  category: 'Clique',
                  action: 'Header',
                  label: 'Logo CashMe'
                })
              }
            >
              <Logo
                type={isMobile ? 'CashmeMobile' : 'Cashme'}
                width={isMobile ? 2 : 7.375}
                height={isMobile ? 2 : 1.375}
              />
            </Link>
          </S.Logo>
        </Col>

        <Col col={{ tablet: 1, desktop: 10 }}>
          <Topbar />
          <S.MenuButton aria-label="Abrir menu lateral" onClick={showSideBar}>
            <Icon name="menu" color="var(--colors-neutral-icon-default)" size={24} />
          </S.MenuButton>
          <Sidebar showSidebar={showSidebar} close={closeSideBar} />
        </Col>
      </Grid>
    </S.Navbar>
  )
}
