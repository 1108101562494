import { DataLayerProps } from './push-data-layer.model'

export const pushDataLayer = ({ event = 'gaEvent', category, action, label = '' }: DataLayerProps): void => {
  if (!global.dataLayer) {
    global.dataLayer = []
  }

  global.dataLayer.push({
    event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  })
}
